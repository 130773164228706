.profile_form {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
  p {
    color: rgb(250, 65, 65);
    font-size: 12px;
  }
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 56px;
  }
  input,
  textarea {
    width: 100%;
    padding: 16px;
    background: #fff;
    border: 1px solid #999ad7;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    outline: none;
  }

  .btn {
    width: 300px;
    margin: 0;
  }

  .btn-block {
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    border-top: 1px solid #999ad7;
    justify-content: center;
    h3 {
      margin: 0;
    }
  }

  .react-tel-input {
    .form-control {
      font-size: 16px;
      height: 57px;
      width: 100%;
      padding: 16px;
      margin-bottom: 24px;
      background: #fff;
      border: 1px solid #999ad7;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      outline: none;
    }

    .flag-dropdown {
      display: none;
    }
  }

  .checkbox_form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 100px;
    .option {
      display: block;
      height: 100px;
      width: 150px;
      margin-right: 30px;
      margin-top: 20px;
    }
    .check {
      cursor: pointer;
    }
    .check__input {
      position: absolute;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .check__box {
      position: relative;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: white;
      background-position: 50% 50%;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      transition: 0.1s all ease-in;
      &:hover {
        background-color: #fabd31;
      }
    }
    .check__input:checked + .check__box {
      background-color: #fabd31;
    }
  }

  //dadata input

  .react-dadata__container {
    position: relative;
    width: 100%;
  }
  .react-dadata__input {
    border-radius: 10px;
    box-sizing: border-box;
    display: block;
    font-size: 14px;
    height: 56px;
    outline: none;
    padding: 0 10px;
    width: 100%;
  }

  .react-dadata__suggestions {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.1);
    left: 0;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: left;
    top: calc(100% + 8px);
    z-index: 10;
  }
  .react-dadata__suggestion-note {
    color: #828282;
    font-size: 14px;
    padding: 10px 10px 5px;
  }
  .react-dadata__suggestion {
    background: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    font-size: 15px;
    padding: 7px 10px;
    text-align: left;
    width: 100%;
  }
  .react-dadata__suggestion--line-through {
    text-decoration: line-through;
  }
  .react-dadata__suggestion-subtitle {
    color: #777;
    font-size: 14px;
    margin-top: 4px;
  }
  .react-dadata__suggestion-subtitle-item {
    display: inline-block;
    margin-bottom: 4px;
    margin-right: 16px;
  }
  .react-dadata__suggestion-subtitle-item:last-child {
    margin-right: 0;
  }
  .react-dadata__suggestion--current {
    background-color: rgba(0, 124, 214, 0.15);
  }
  .react-dadata__suggestion:hover {
    background-color: rgba(0, 124, 214, 0.1);
  }
  .react-dadata mark {
    background: none;
  }

  .group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &_item {
      display: flex;
      flex-direction: column;
      align-items: start;
      flex: 1;
      padding: 10px;
      min-width: 250px;
    }
  }
}
