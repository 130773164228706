.order_form {
  max-width: 600px;
  margin: 0 auto;
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 56px;
  }
  p {
    color: rgb(250, 65, 65);
    font-size: 12px;
  }
  input,
  textarea {
    width: 100%;
    padding: 16px;
    background: #fff;
    border: 1px solid #999ad7;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    outline: none;
  }

  .group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &_item {
      display: flex;
      flex-direction: column;
      align-items: start;
      flex: 1;
      padding: 10px;
      min-width: 250px;
    }
  }
  .checkbox_form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    .option {
      display: block;
      height: 100px;
      width: 150px;
      margin-top: 10px;
    }
    .check {
      cursor: pointer;
    }
    .check__input {
      position: absolute;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    .check__box {
      position: relative;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: white;
      background-position: 50% 50%;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      transition: 0.1s all ease-in;
      &:hover {
        background-color: #999ad7;
      }
    }
    .check__input {
      width: 0;
    }
    .check__input:checked + .check__box {
      background-color: #999ad7;
    }

    .check__input:disabled + .check__box {
      background-color: #dbdbdb;
    }
  }

  .weight {
    .check__box {
      width: 120px;
      height: 50px;
    }

    .option {
      width: 120px;
      height: 50px;
    }
  }

  .react-tel-input {
    .form-control {
      font-size: 16px;
      height: 57px;
      width: 100%;
      padding: 16px;
      margin-bottom: 24px;
      background: #fff;
      border: 1px solid #999ad7;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      outline: none;
    }

    .flag-dropdown {
      display: none;
    }
  }

  .form_adress {
    width: 100%;
  }

  .cash_points {
    display: flex;
    flex-direction: column;
    justify-content: center;
    input {
      width: 10px;
    }
    label {
      margin: 0;
      cursor: pointer;
      input {
        margin: 0px;
        margin-right: 10px;
      }
    }

    .check {
      display: flex;
      align-items: center;
    }
  }

  .btn-block {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    h3 {
      margin: 0;
    }
  }
}

.back {
  display: flex;
  align-items: start;
  margin-left: 250px;
  a {
    position: relative;
    color: #000;
    opacity: 0.6;
    font-size: 24px;
    &::before {
      transition: all 0.3s ease;
      position: absolute;
      content: "";
      left: 0;
      background-color: #000;
      bottom: -3px;
      height: 2px;
      width: 0%;
      opacity: 0;
    }
    &:hover {
      opacity: 1;
      &::before {
        width: 100%;
        opacity: 1;
      }
    }
  }
}

.form_adress {
  position: relative;
}

.react-dadata__suggestions {
  position: absolute;
  margin: 10px 0 0 0;
  padding: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 6px 3px rgba(0, 0, 0, 0.1);
  list-style: none;
  overflow: hidden;
  text-align: left;
  z-index: 10;
}
.react-dadata__suggestion-note {
  color: #828282;
  font-size: 14px;
  padding: 10px 10px 5px;
}
.react-dadata__suggestion {
  background: none;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 15px;
  padding: 7px 10px;
  text-align: left;
  width: 100%;
}
.react-dadata__suggestion--line-through {
  text-decoration: line-through;
}
.react-dadata__suggestion-subtitle {
  color: #777;
  font-size: 14px;
  margin-top: 4px;
}
.react-dadata__suggestion-subtitle-item {
  display: inline-block;
  margin-bottom: 4px;
  margin-right: 16px;
}
.react-dadata__suggestion-subtitle-item:last-child {
  margin-right: 0;
}
.react-dadata__suggestion--current {
  background-color: rgba(0, 124, 214, 0.15);
}
.react-dadata__suggestion:hover {
  background-color: rgba(0, 124, 214, 0.1);
}
.react-dadata mark {
  background: none;
}

.react-datepicker__input-container {
  input {
    width: 200px;
  }
}
