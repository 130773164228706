.delivery {
  margin-top: 200px;
  &-content {
    &-title {
      margin-bottom: 56px;
      h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
      }
    }
    &-cards {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        text-align: start;
        margin: 12px;
        padding: 32px 32px 0 32px;
        width: 306px;
        height: 312px;
        background: #f6f6f6;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: all 0.2s ease;
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          opacity: 0.6;
        }
        img {
          margin-left: -32px;
        }
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  @media (max-width: 753px) {
    margin-top: 50px;
  }
}
