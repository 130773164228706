header {
  margin: 24px 0;
  font-size: 14px;
  display: flex;
  justify-content: center;
  .header-content-mobile {
    display: none;
  }
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    display: flex;
  }

  .location {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    opacity: 0.8;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
    img {
      margin-right: 4px;
    }
    &:hover {
      color: #999ad7;
    }
  }

  nav {
    .nav-menu-link {
      margin: 0 12px;
      opacity: 0.8;
      transition: all 0.2s ease-in;
      color: #000;
      &:hover {
        opacity: 1;
        color: #000;
      }
    }
  }

  .btn-header {
    padding: 0;
    position: relative;
    margin: 0 10px;
    font-size: 14px;
    font-weight: 700;
    border: none;
    background: transparent;
    a {
      color: #000;
    }
    &::before {
      position: absolute;
      content: "";
      left: 0;
      bottom: -8px;
      width: 0%;
      height: 4px;
      background: #111a1b;
      opacity: 0;
      z-index: -5;
      transition: all 0.1s ease-in;
    }

    &:hover {
      &::before {
        width: 100%;
        opacity: 1;
      }
    }

    &:active,
    &:focus {
      border: none;
    }
  }

  .btn-block {
    display: flex;
    flex-wrap: wrap;
  }
  .btn-lk {
    display: block;
    position: relative;
    width: 32px;
    height: 32px;
    margin-left: 20px;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: all 0.3s ease;
    .person-link {
      position: absolute;
      left: 16px;
      top: 15px;
      transform: translate(-50%, -50%);
    }
    &:hover {
      background-color: #000;
      .person {
        fill: #fff;
      }
    }
  }

  @media (max-width: 1400px) {
    nav {
      .nav-menu-link {
        margin: 0 5px;
      }
    }
    .btn-header {
      margin: 0 8px;
    }
  }

  @media (max-width: 1200px) {
    .header-content-mobile {
      display: flex;
      justify-content: space-between;
    }
    .header-content {
      display: none;
    }
  }
}

.location_list {
  list-style: none;
  width: 200px;
  margin: 0 20px 0 0;
  padding: 0;
  li {
    &:hover {
      color: #999ad7;
      cursor: pointer;
    }
  }
}

.modal-md {
  min-width: 600px !important;
  @media (max-width: 700px) {
    min-width: 95% !important;
  }
}
