.regions {
  margin-bottom: 100px;

  &-content {
    &-title {
      h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
        margin-top: 200px;
        margin-bottom: 112px;
      }
    }
    &-string {
      position: relative;
      .ellipse {
        position: absolute;
        top: -280px;
        left: -220px;
        z-index: -999;
      }
      @media (max-width: 1400px) {
        .ellipse {
          left: -420px;
        }
      }
    }
  }
}
