.works {
  margin-top: 200px;
  @media (max-width: 600px) {
    margin-top: 70px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    &-title {
      h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 56px;
      }
    }
    &-steps {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (max-width: 440px) {
        justify-content: center;
      }
      .ellipse {
        position: absolute;
        right: -660px;
        top: -170px;
        width: 624px;
      }
      &-item {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 208px;
        margin: 10px 0px;
        h3 {
          font-weight: 400;
          font-size: 96px;
          line-height: 96px;
          color: #999ad7;
        }
        span {
          font-weight: 500;
          font-size: 24px;
          line-height: 24px;
          margin-top: 24px;
          margin-bottom: 16px;
          color: #060606;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          opacity: 0.6;
        }
      }
    }
  }
}
