.region {
  display: flex;
  align-items: center;
  background: #f6f6f6;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  margin: 10px 28px;
  padding: 16px 24px;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  img {
    margin-right: 15px;
  }
}
