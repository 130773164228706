.advantages {
  margin-top: 60px;
  &-content {
    &-title {
      h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
      }
    }
    &-card {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 46px;
      @media (max-width: 753px) {
        & {
          justify-content: center;
        }
      }
      &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px;
        padding: 40px 55px;
        width: 306px;
        height: 312px;
        background: #f6f6f6;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: all 0.2s ease-in;
        img {
          // width: 50px;
        }
        span {
          font-weight: 400;
          font-size: 32px;
          line-height: 32px;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          opacity: 0.8;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
