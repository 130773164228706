.App {
  font-family: "Raleway", sans-serif;
  text-align: center;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  .btns {
    background-color: #999ad7;
    border-radius: 100px;
    padding: 24px 36px;
    font-size: 20px;
    line-height: 16px;
    border: none;
    transition: all 0.1s ease-out;
    color: #000;
    &:hover {
      background-color: lighten($color: #999ad7, $amount: 5);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    }
    &:active {
      background-color: #999ad7;
    }
  }

  a {
    text-decoration: none;
  }
}
