.tarifs {
  margin-top: 180px;
  &-content {
    display: flex;
    flex-direction: column;
    &-title {
      margin-bottom: 56px;
      h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
      }
    }
    &-cards {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      &-item {
        display: flex;
        flex-direction: column;
        margin: 12px;
        width: 342px;
        height: 528px;
        background: #f6f6f6;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: all 0.2s ease;
        &:hover {
          transform: scale(1.05);
        }
        &-title {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 92px;
          min-height: 128px;
          background: rgba(153, 154, 215, 0.6);
          border-radius: 10px 10px 0px 0px;
          h3 {
            font-weight: 400;
            font-size: 32px;
          }
        }
        &-body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 32px 48px;
          height: 100%;
          ul {
            text-align: left;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            opacity: 0.6;
            margin-top: 20px;
            li {
              margin-top: 24px;
            }
          }
          span {
            font-weight: 400;
            font-size: 32px;
            line-height: 32px;
          }
          .btns {
            font-weight: 400;
            font-size: 20px;
            line-height: 16px;
            padding: 20px 34px !important;
          }
        }
      }
      @media (max-width: 400px) {
        .tarifs-content-cards-item {
          .btns {
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
