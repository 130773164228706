footer {
  margin-top: 100px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 32px 0;
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 1200px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    &-payments {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      p {
        margin: 0;
      }
    }

    a {
      position: relative;
      color: #000;
      opacity: 0.8;

      &::before {
        transition: all 0.3s ease;
        position: absolute;
        content: "";
        left: 0;
        background-color: #000;
        bottom: -3px;
        height: 2px;
        width: 0%;
        opacity: 0;
      }
      &:hover {
        opacity: 1;
        &::before {
          width: 100%;
          opacity: 1;
        }
      }
    }
    ul {
      margin: 0;
      li {
        list-style: none;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
}
