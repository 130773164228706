.payments {
  background: #999ad799;
  margin-top: 200px;
  padding: 80px 0;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      max-width: 560px;
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        opacity: 0.6;
        margin: 56px 0;
      }
      &-icon {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        &-item {
          display: flex;
          flex-direction: column;

          margin: 0 48px;

          img {
            transition: all 0.3s ease;
            &:hover {
              transform: scale(1.15);
            }
          }
          span {
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
            margin-top: 32px;
          }

          @media (max-width: 753px) {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
