.contacts {
  margin-bottom: 370px;
  &-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    &-info {
      display: flex;
      justify-content: space-around;
      @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
      }
      &-adress {
        max-width: 400px;
      }
    }
    a {
      position: relative;
      color: #000;
      opacity: 0.6;
      &::before {
        transition: all 0.3s ease;
        position: absolute;
        content: "";
        left: 0;
        background-color: #000;
        bottom: -3px;
        height: 2px;
        width: 0%;
        opacity: 0;
      }
      &:hover {
        opacity: 1;
        &::before {
          width: 100%;
          opacity: 1;
        }
      }
    }
    &-title {
      display: flex;
      align-items: center;
      text-align: center;
      ion-icon {
        font-size: 20px;
      }
      h2 {
        margin-left: 30px;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
      }
    }
    &-info {
      margin-top: 50px;
      ul {
        list-style: none;
        padding-left: 0;
      }
    }
  }
}
