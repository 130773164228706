.main-content {
  display: flex;
  .main-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 200px;
    text-align: start;
    color: #000;

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      opacity: 0.4;
      margin: 56px 0;
    }

    h1 {
      font-weight: 700;
      font-size: 72px;
      line-height: 80px;
      @media (max-width: 600px) {
        font-size: 54px;
        line-height: 1;
      }
    }
  }

  .main-img {
    position: relative;
    .man {
      max-width: 580px;
    }

    .ellipse {
      position: absolute;
      left: 110px;
      top: -616px;
      z-index: -999;
    }
    @media (max-width: 1400px) {
      .ellipse {
        top: -610px;
        left: 120px;
      }
    }
  }

  @media (max-width: 992px) {
    .main-info {
      margin-top: 30px;
    }
    .main-img {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .main-info {
      margin-top: 30px;
      text-align: center;
      align-items: center;
    }
  }
}
