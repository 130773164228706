.feedback {
  margin-top: 200px;
  @media (max-width: 600px) {
    margin-top: 80px;
  }
  &-content {
    &-title {
      h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 56px;
      }
    }
    &-form {
      position: relative;
      display: flex;
      justify-content: center;
      .ellipse {
        position: absolute;
        width: 624px;
        left: -550px;
        top: -50px;
      }
      .alert {
        width: 100%;
        padding: 15px;
        background: #f6f6f6;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }
      .fb-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 415px;
        input,
        textarea {
          width: 100%;
          padding: 18px;
          margin-bottom: 24px;
          background: #f6f6f6;
          border: 1px solid #999ad7;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          outline: none;
          font-size: 12px;
        }
        label {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #060606;
          margin-bottom: 16px;
        }
        p {
          margin-top: -16px;
          color: rgb(253, 78, 78);
        }
        .form_btn {
          display: flex;
          justify-content: center;
          margin-top: 16px;
          width: 100%;
          .btns {
            padding: 24px 34px;
          }
        }
      }
    }
  }
}
